import { graphql } from 'gatsby';
import * as React from 'react';
import loadable from '@loadable/component';
import {  sectionList } from '../../constants/queryFragments';
import { Helmet } from 'react-helmet';
import SlpGlobalStyles from '../../styles/slpGlobalStyles';
import logo from '../../images/favicon.png';
const StandalonePageTemplate = ({ data }) => {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  const sectionsArray = data?.allContentfulFasGasPlus?.edges[0]?.node?.sections;
  const metaData = data?.allContentfulFasGasPlus?.edges[0]?.node;

  return (
    <>
      <SlpGlobalStyles />
      <Helmet>
        <title>{ metaData?.title + ' - FasGas'}</title>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"></meta>
        {metaData?.metadata && (
          <meta property="og:description" content={metaData?.metadata}></meta>
        )}
        {metaData?.title && (
          <meta property="og:title" content={metaData?.title}></meta>
        )}
        <meta property="og:type" content="website"></meta>
        <meta property="og:image" content={logo}></meta>
        <meta property="og:url" content={process.env.GATSBY_DOMAIN_URL}></meta>
        {metaData?.title && (
          <meta name="twitter:title" content={metaData?.title}></meta>
        )}
        {metaData?.metadata && (
          <meta name="twitter:description" content={metaData?.metadata}></meta>
        )}
        <meta name="twitter:image" content={logo}></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
        {metaData?.hasIndex === false && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <>
        {sectionsArray &&
          sectionsArray.map((section, index) => {
            if (
              section?.type && 
              sectionList.includes(section?.type)
            ) {
              const SectionComponent = loadable(() =>
                import(`../../components/${section?.type}/${section?.type}`)
              );
              return <SectionComponent key={index} sectionData={section} />;
            }
            return <></>;
          })}
      </>
    </>
  );
};

export default StandalonePageTemplate;

export const pageQuery = graphql`
  query allStandalonePagesQuery(
    $pageIdentifier: String!
    $contentfulContent: Boolean!
  ) {
    allContentfulFasGasPlus(
      filter: { identifier: { type: { eq: $pageIdentifier } } }
    ) {
      edges {
        node {
          name
          identifier {
            type
          }
          slug
          title
          metadata
          node_locale
          hasIndex
          sections {
            ...PageContent @include(if: $contentfulContent)
          }
        }
      }
    }
  }
`;
