import { createGlobalStyle } from 'styled-components';


const SlpGlobalStyles = createGlobalStyle`
/* This is used to test if the stylesheet has been loaded yet*/
@font-face {
    font-family: 'Gotham-Book';
    src: url('../fonts/gothambook.eot');
    src: url('../fonts/Gotham-Book.woff') format('woff'),
         url('../fonts/gothambook.eot'),
         url('../fonts/gothambook.ttf') format('truetype'),
         url('../fonts/gothambook.svg#gothambook') format('svg');
    font-weight: normal;
    font-style: normal;
    }
/* FORCE CHROME TO READ SVG */
@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: 'Gotham-Book';
        src: url('../fonts/gothambook.svg#gothambook') format('svg');
    }
}

@font-face {
    font-family: 'Gotham-Light';
    src: url('../../fonts/gothamlight.eot');
    src: url('../../fonts/Gotham-Light.woff') format('woff'),
         url('../../fonts/gothamlight.eot'),
         url('../../fonts/gothamlight.ttf') format('truetype'),
         url('../../fonts/gothamlight.svg#gothamlight') format('svg');
    font-weight: normal;
    font-style: normal;
    }
/* FORCE CHROME TO READ SVG */
@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: 'Gotham-Light';
        src: url('../../fonts/gothamlight.svg#gothamlight') format('svg');
    }
}

@font-face {
    font-family: 'Gotham-Medium';
    src: url('../fonts/gothammedium.eot');
    src: url('../fonts/Gotham-Medium.woff') format('woff'),
         url('../fonts/gothammedium.eot'),
         url('../fonts/gothammedium.ttf') format('truetype'),
         url('../fonts/gothammedium.svg#gothammedium') format('svg');
    font-weight: normal;
    font-style: normal;
    }
/* FORCE CHROME TO READ SVG */
@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: 'Gotham-Medium';
        src: url('../fonts/gothammedium.svg#gothammedium') format('svg');
    }
}

@font-face {
    font-family: 'Gotham-Bold';
    src: url('../fonts/gothambold.eot');
    src: url('../fonts/Gotham-Bold.woff') format('woff'),
         url('../fonts/gothambold.eot'),
         url('../fonts/gothambold.ttf') format('truetype'),
         url('../fonts/gothambold.svg#gothambold') format('svg');
    font-weight: normal;
    font-style: normal;
    }
/* FORCE CHROME TO READ SVG */
@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: 'Gotham-Bold';
        src: url('../fonts/gothambold.svg#gothambold') format('svg');
    }
}
    
/*------------------------------
1. GENERAL 
------------------------------*/

* {
    box-sizing: border-box;		
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-text-size-adjust: none;
    }
    
body {
    margin: 0;
    padding: 0;
    color: #198e48;
    /*background: #f7f7f7;*/

    font-family: 'Gotham-Book', arial, sans-serif;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: 0.02em;
    }

img {
    border: 0;
    vertical-align: top;
    }

h1 {	
    margin: 0 0 35px 0;
    padding: 0;		
    font-family: 'Gotham-Bold', arial, sans-serif;
    font-weight: normal !important;
    color: #198e48;
    font-size: 50px;
    line-height: 60px;
    }
    
h2 {			
    margin: 0 0 35px 0;
    padding: 0;		
    font-family: 'Gotham-light', arial, sans-serif;
    font-weight: normal !important;
    color: #004e1f;
    font-size: 22px;
    line-height: 32px;

    }
    
h3 {			
    margin: 0 0 0 0;
    padding: 0;		
    font-family: 'Gotham-book', arial, sans-serif;
    font-weight: normal !important;
    color: #198e48;
    font-size: 20px;
    line-height: 32px;
    text-align:center;

    }
        
hr {
    height: 1px;
    border: none;
    background: #bdc2c5;
    margin: 50px 0;
    clear: both;
    }
    
p {		
    margin: 0 0 20px 0;
    padding: 0;
    
    }
    
strong {
    font-family: 'Gotham-Bold', arial, sans-serif;
    }
    
a:hover, a:active, a:focus, object { 
    outline: none; 
    }			
a {
    text-decoration: none;
    color: #1d9cd7;	
    webkit-transition: color .15s ease-out;
    -moz-transition: color .15s ease-out;
    -o-transition: color .15s ease-out;
    -ms-transition: color .15s ease-out;
    transition: color .15s ease-out;
    }
a:hover {
    text-decoration: none;
    color: #198e48;
    }

form, 
input,
textarea {
    margin: 0;
    padding: 0;
    outline: none;
    -webkit-border-radius:0; 
    }

sup,
sub {
    font-size: 18px;
    line-height: 18px;
    vertical-align: top;
    }

.clear {
    clear: both;
    }
.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
    }

/*------------------------------
2. LAYOUTS
------------------------------*/
    
.head-container {
    background: #004e1f;
    }
    .head {
        width: 152px;
        height: 136px;
        margin: 0 auto;
        padding: 23px 0 0 0;
        }			
        .head-logo {
            float: left;
            width: 150px;
            height: auto;
            padding: 0 18px 0 0;
            text-align: right;
            }	


.banner-container {
    background: #198e48;
    }
    .banner {
        width: 740px;
        height: auto;
        margin: 0 auto;
        padding: 55px 75px;
        }
        .banner p {
            margin: 0;
            font-family: 'Gotham-Book', arial, sans-serif;
            font-size: 46px;
            line-height: 56px;
            color: #fff;
            }

.whiteBar-container {
    background: #fff;
    }
    .whiteBar {
        width: 1100px;
        margin: 0 auto;
        padding: 40px 0 310px 0;
        text-align: left;
        }	
                
.form-container {
    background: #fff;
    }
    .form {
        width: 740px;
        margin: 0 auto;
        padding: 0 0 30px 0;
        text-align: center;
        }
        .form h1 {
            font-size: 34px;
            line-height: 34px;
            margin: 25px 0 20px 0;
            }
        .form .note {
            margin: 0 0 30px 0;
            }
        .form form {
            display: inline-block;
            }
p.note {
font-size: 10px;
}

.foot-container {
    background: #ededed;
    }
    .foot {
        width: 100%;
        margin: 0 auto;
        padding: 30px;
        text-align: center;
        font-size: 12px;
        line-height: 130%;
        color: #9a9a9a;
        margin-bottom: 0px;
        }
        .landing-shell-logo {
            display: block;
            margin: 0 auto;
            text-align: center;
            padding: 20px 0;
            opacity: .30;
            }

.terms-bar {
background: #198e48;
width: 100%;
height: 50px;
text-align: center;
}

.terms{
padding-top: 14px;
}

.terms a {
color:#fff;
font-size: 18px;
}

.terms a:hover {
color: #1d9cd7;
}


/* BUTTONS */

.button {
    display: inline-block;		
    padding: 16px 85px 14px 15px;
    margin: 0 5px 5px 0;
    font-family: 'Gotham-Medium', arial, sans-serif;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase !important;
    text-align: left;
    color: #037ec4;
    background: #ebf5fa url('../images/arrow-blue-small.png') no-repeat 99% center;
    background-size: 23px auto;
    -webkit-transition: background-position 0.15s ease-out;
    -moz-transition: background-position 0.15s ease-out;
    -o-transition: background-position 0.15s ease-out;
    -ms-transition: background-position 0.15s ease-out;
    transition: background-position 0.15s ease-out;
    border: none;
    cursor: pointer;
    }
.button:hover {
    background-position: 95% center;
    }

/* EXPAND/COLLAPSE */

.expand {
        position: relative;
        padding: 0;
        margin: 0;
        }
        .expand-link {
            display: block;
            padding: 15px 70px 15px 15px;
            margin: 0;
            border-bottom: 1px solid #e5e5e5;
            font-family: 'Gotham-Light', arial, sans-serif;
            font-weight: normal !important;
            color: #48b4f1;
            font-size: 20px;
            line-height: 25px;
            background-repeat: no-repeat;
            background-position: center right;
            background-color: #fff;
            background-size: 39px auto;
            background-image: url('../images/arrow-blue-down.png');
            webkit-transition: background-color .15s ease-out;
            -moz-transition: background-color .15s ease-out;
            -o-transition: background-color .15s ease-out;
            -ms-transition: background-color .15s ease-out;
            transition: background-color .15s ease-out;
            cursor: pointer;
            text-align: left;
            }
        .expand-link:hover {
            color: #037ec4;
            background-repeat: no-repeat;
            background-position: center right;
            background-color: #f5f5f5;
            background-size: 39px auto;
            background-image: url('../images/arrow-blue-down.png');
            }
        .expand-link.active {				
            background-repeat: no-repeat;
            background-position: center right;
            background-color: #f5f5f5;
            background-size: 39px auto;
            background-image: url('../images/arrow-blue-up.png');
            color: #037ec4;
            }			
        .expand-content {
            position: relative;
            display: none;
            margin: 20px 0 0 0;
            padding: 0 20px;
            }				
            .expand-content p {
                text-align: left;
                }	



/* ASPECT RATIO WRAPPER */

.aspectRatioWrapper {
    position: relative;
    width: 100%;
    padding-bottom: 56%;
    margin: 40px 0 40px 0;
    }
    .aspectRatioWrapper iframe {
        position: absolute;
        top: 0; 
        bottom: 0; 
        left: 0; 
        right: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 1px 3px #969696;
        }

.hide-desktop {
    display: none;
    }
.show-desktop {
    display: block;
    }

/*----------------------------------------------------------------------------------------------------------------------------------------
   3. RESPONSIVE CASES - SCALES SITE DOWN BELOW 1100
----------------------------------------------------------------------------------------------------------------------------------------*/
                
@media screen and (max-width: 1100px) {	

.head {
    width: 100%;
    height: auto;
    padding: 25px 0;
    }
    .head-logo {
        float: none;
        padding: 0 0 10px 0;
        display: block;
        margin: 0 auto;
        text-align: center;
        }
    .head-tagline {
        float: none;
        width: 100%;
        padding: 0;
        margin: 0;
        text-align: center;
        font-size: 13px;
        line-height: 13px;
        }	

.banner,
.nav,
.whiteBar,
.form,
.foot {
    width: 100%;
    height: auto;
    }

h1 {
font-size: 30px;
line-height: 40px;
}

h2 {
font-size: 18px;
line-height: 30px;
}
    
.banner-container {
    background-size: 1500px auto;
    }
    .banner {
        padding: 55px 35px;
        }			
        .banner p {
            font-size: 28px;
            line-height: 40px;
            }

.whiteBar {
    padding: 30px;
    
    }	
.foot-container {
    position: relative;
    bottom:-100px;
    width: 100%;
}
        
}`;

export default SlpGlobalStyles;